export const hotelList = [
  "Hotels near me",
  "Hotels in Manali",
  "Hotels in Nainital",
  "Hotels in Mount Abu",
  "Hotels in Agra",
  "Hotels in Haridwar",
  "Hotels in Gurgaon",
  "Hotels in Coimbatore",
  "Roomsstay Hotel UK",
  "Vacation Homes in Europe",
  "Hotels in Goa",
  "Hotels in Udaipur",
  "Hotels in Lonavala",
  "Hotels in Kodaikanal",
  "Hotels in Gangtok",
  "Hotels in Kolkata",
  "Hotels in Mandarmoni",
  "Hotels in Kasauli",
  "Roomsstay Hotel USA",
  "Homes in Scandinavia",
  "Hotels in Puri",
  "Hotels in Mussoorie",
  "Hotels in Munnar",
  "Hotels in Hyderabad",
  "Hotels in Coorg",
  "Hotels in Ahmedabad",
  "Hotels in Daman",
  "Hotels in Dehradun",
  "Roomsstay Hotel Mexico",
  "Homes in Southern Europe",
  "Hotels in Shimla",
  "Hotels in Mumbai",
  "Hotels in Darjeeling",
  "Hotels in Shirdi",
  "Hotels in Dalhousie",
  "Hotels in Varanasi",
  "Hotels in Madurai",
  "Coupons",
  "Roomsstay Hotel Indonesia",
  "Traum Holiday Homes",
];
