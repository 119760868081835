// Define filter options
import {
  FaWineGlass,
  FaUtensils,
  FaPhone,
  FaCoffee,
  FaDoorOpen,
  FaHotTub,
  FaTv,
  FaWifi,
  FaDumbbell,
  FaShuttleVan,
  FaSwimmingPool,
  FaSpa,
  FaChalkboardTeacher,
  FaParking,
  FaDog,
  FaTshirt,
  FaBriefcase,
  FaCouch,
  FaSnowflake,
  FaFireAlt,
  FaShower,
  FaPhoneAlt,
  FaBroom,
  FaSoap,
  FaSuitcase,
  FaLock,
  FaClock,
  FaThermometer,
  FaSmokingBan,
  FaVolumeMute,
  FaBed,
  FaWheelchair,
  FaPlaneDeparture,
  FaUserFriends,
  FaCar,
  FaMoneyBillAlt,
  FaGift,
  FaClipboardCheck,
  FaMapMarkedAlt,
  FaTicketAlt,
  FaBookOpen,
  FaSun,
  FaLeaf,
  FaTree,
  FaChair,
  FaCity,
  FaCocktail,
  FaCandyCane,
  FaAppleAlt,
  FaHamburger,
  FaCookieBite,
  FaChild,
  FaBan,
  FaCarrot,
  FaMosque,
  FaStarOfDavid,
  FaAllergies,
  FaSmoking,
} from "react-icons/fa";
import { BiSolidDryer } from "react-icons/bi";
import { LuRefrigerator } from "react-icons/lu";
import { FaElevator } from "react-icons/fa6";
import { GiDesk } from "react-icons/gi";
import { MdMicrowave } from "react-icons/md";


export const roomTypes = [
  "Single Room",
  "Double Room",
  "Suite",
  "Deluxe Room",
  "Executive Room",
  "Family Room",
  "Penthouse",
  "Studio",
];

export const propertyTypes = [
  "Hotel",
  "Motel",
  "Hostel",
  "Guesthouse",
  "Bed and Breakfast",
  "Apartment",
  "Resort",
  "Villa",
  "Inn",
  "Chalet",
];

export const bedTypes = [
  "King Size Bed",
  "Queen Size Bed",
  "Double Bed",
  "Single Bed",
  "Twin Beds",
  "Sofa Bed",
  "Rollaway Bed",
  "Bunk Beds",
  "Extra Large Bed",
];

export const starRatings = ["1", "2", "3", "4", "5"];

const amenityIcons = {
  "Continental Breakfast": <FaUtensils />,
  "Room Service": <FaPhone />,
  "Coffee Maker": <FaCoffee />,
  Balcony: <FaDoorOpen />,
  Jacuzzi: <FaHotTub />,
  TV: <FaTv />,
  "Free Wi-Fi": <FaWifi />,
  Gym: <FaDumbbell />,
  "24-Hour Front Desk": <FaShuttleVan />,
  "Shuttle Service": <FaShuttleVan />,
  Pool: <FaSwimmingPool />,
  "Fitness Center": <FaDumbbell />,
  Spa: <FaSpa />,
  Restaurant: <FaUtensils />,
  "Conference Room": <FaChalkboardTeacher />,
  "Wi-Fi(Paid)": <FaWifi />,
  Parking: <FaParking />,
  "Pet Friendly": <FaDog />,
  "Laundry Service": <FaTshirt />,
  "Business Center": <FaBriefcase />,
  "Lounge Area": <FaCouch />,
  "Air Conditioning": <FaSnowflake />,
  "Barbecue Area": <FaFireAlt />,
  "Ensuite Bathroom": <FaShower />,
  Telephone: <FaPhoneAlt />,
  "Daily Housekeeping": <FaBroom />,
  "Complimentary Toiletries": <FaSoap />,
  Closet: <FaSuitcase />,
  "Iron and Ironing Board": <FaTshirt />,
  "Hair Dryer": <BiSolidDryer />,
  Safe: <FaLock />,
  "Mini Fridge": <LuRefrigerator />,
  Microwave: <MdMicrowave />,
  Desk: <GiDesk />,
  "Wake-up Service": <FaClock />,
  Heating: <FaThermometer />,
  "Cable Channels": <FaTv />,
  "Non-Smoking Rooms": <FaSmokingBan />,
  "Soundproof Rooms": <FaVolumeMute />,
  "Family Rooms": <FaBed />,
  Elevator: <FaElevator />,
  "Wheelchair Accessible": <FaWheelchair />,
  "Airport Shuttle": <FaPlaneDeparture />,
  "Concierge Service": <FaUserFriends />,
  "Valet Parking": <FaCar />,
  "Currency Exchange": <FaMoneyBillAlt />,
  "ATM on Site": <FaMoneyBillAlt />,
  "Gift Shop": <FaGift />,
  "Express Check-in/Check-out": <FaClipboardCheck />,
  "Tour Desk": <FaMapMarkedAlt />,
  "Ticket Service": <FaTicketAlt />,
  "Luggage Storage": <FaSuitcase />,
  Library: <FaBookOpen />,
  "Sun Terrace": <FaSun />,
  Garden: <FaLeaf />,
  "Picnic Area": <FaTree />,
  "Outdoor Furniture": <FaChair />,
  Terrace: <FaCity />,
  "BBQ Facilities": <FaFireAlt />,
  "Vending Machine (Drinks)": <FaCocktail />,
  "Vending Machine (Snacks)": <FaCandyCane />,
  "Special Diet Menus (on request)": <FaAppleAlt />,
  "Packed Lunches": <FaHamburger />,
  Bar: <FaCocktail />,
  "Wine/Champagne": <FaWineGlass />,
  "Bottle of Water": <FaCocktail />,
  "Chocolate/Cookies": <FaCookieBite />,
  "Kid-Friendly Buffet": <FaChild />,
  "Kid Meals": <FaChild />,
  "Breakfast in the Room": <FaUtensils />,
  "Restaurant Buffet": <FaUtensils />,
  "Snack Bar": <FaUtensils />,
  Fruit: <FaAppleAlt />,
  "Buffet Breakfast": <FaUtensils />,
  "Gluten-Free Options": <FaBan />,
  "Vegetarian Options": <FaCarrot />,
  "Vegan Options": <FaLeaf />,
  "Halal Options": <FaMosque />,
  "Kosher Options": <FaStarOfDavid />,
  "Allergy-Free Room": <FaAllergies />,
  "Designated Smoking Area": <FaSmoking />,
  "Non-Smoking Throughout": <FaSmokingBan />,
  Kitchen: <FaUtensils />,
};
export default amenityIcons;
